<!-- =========================================================================================
    File Name: VxTimeline.vue
    Description: Timeline Component
    Component Name: VxTimeline
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <ul class="vx-timeline">
    <li v-for="item in data" :key="item.title">
      <div class="timeline-icon" :class="`bg-${item.color}`">
        <feather-icon :icon="item.icon" svgClasses="text-white stroke-current w-5 h-5" />
      </div>
      <div class="timeline-info">
        <span class="cd-p-normal">{{ item.desc }}</span>
      </div>
      <small class="cd-date">{{ item.time }}</small>
    </li>
  </ul>
</template>

<script>
export default {
  name: "vx-timeline",
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";
</style>
