<template>
  <div>
    <div class="vx-row" id="notify-list">
      <!-- ACTIVITY TIMELINE -->
      <div class="list vx-col w-full mb-base">
          <vx-card :title="$t('notify.title')">
            <vx-timeline :data="timelineData" v-if="timelineData.length!=0"></vx-timeline>
            <div v-else>{{$t('notify.nodata')}}!</div>
          </vx-card>
      </div>
    </div>
  </div>
</template>

<script>

import { fetchMessage } from '@/api/user'
import VxTimeline       from "@/components/timeline/VxTimeline.vue"

export default {
  data() {
    return {
      total: 0,
      timelineData: [],
      next: 1,
      scroll: 0,
      nodataFlag: 0,
      loadingFlag: false,
    }
  },
  components: {
    VxTimeline
  },
  methods: {
    // 取 通知 List
    async getList(page) {
      var _self = this
      await fetchMessage(page).then(res => {
        var tmpdata = res.data.items
        if(tmpdata.length != 0){
          tmpdata.forEach(function(v, i){
            _self.timelineData.push({
              color : "grey",
              icon  : "MessageSquareIcon",
              desc  : v.pm_text,
              time  : v.cre_date
            })
          })
        }else{
          _self.nodataFlag = 1
        }
      })
      await _self.delay(0.5)
      _self.loadingFlag = false
    },
    // 延遲(秒)
    delay(n){
      return new Promise(function(resolve){
        setTimeout(resolve,n*1000);
      });
    },
    // 滾動時觸發
    scrolling(){
      let box = document.querySelector('.list')
      let bottomOfWindow = box.offsetHeight - document.documentElement.scrollTop - window.innerHeight
      if(bottomOfWindow <= 200 && this.nodataFlag == 0 && !this.loadingFlag){
        this.loadingFlag = true
        this.next++
        this.getList(this.next)
      }
    },
  },
  mounted() {
    var _self = this
    _self.getList(_self.next)
    _self.$store.commit('SET_NOTIFY_UNFINISH_TOTAL', 0)
    window.addEventListener('scroll', _self.scrolling)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrolling)
  },
}
</script>
